<template>
    <router-view/>
</template>

<script>
export default {
    name: "app",
    methods: {
        getUserData() {
            let self = this;
            this.$api.get(`userdata/${self.$store.state.token}`).then(res => {
                if (res.data.success) {
                    self.$store.commit('userData',res.data.data);
                } else {
                    //self.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
    },
    created() {
        this.getUserData();
    }
}
</script>

<style lang="scss">
@import "assets/scss/base";


</style>
